<template>
  <div id="formSteps">
    <v-app>
      <v-row class="m-0">
        <v-col cols="12" class="app paddingOut">
          <v-stepper v-model="currentStep" color="green">
            <v-row class="pb-5">
              <v-col cols="12" class="">
                <v-stepper-header class="step_tollbar">
                  <v-stepper-step
                    v-for="(step, n) in steps"
                    :key="n"
                    :complete="stepComplete(n + 1)"
                    :step="n + 1"
                    :color="stepStatus(n + 1)"
                  >
                    {{ step.name }}
                  </v-stepper-step>
                </v-stepper-header>
              </v-col>
            </v-row>

            <v-stepper-content
              v-for="(step, n) in steps"
              :step="n + 1"
              :key="n"
              class="ma-0"
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="paddingform"
              >
                <b-overlay :show="show" rounded="sm">
                  <div v-if="currentStep == 1">
                    <h1 class="titles mt-3">Dados Pessoais</h1>

                    <v-row>
                      <v-col cols="12" md="9">
                        <v-text-field
                          v-model="form.full_name"
                          label="Nome completo:"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.full_name.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>

                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          type="date"
                          v-model.trim="form.birth_date"
                          label="Data de Nascimento"
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.birth_date.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.cpf"
                          label="CPF:"
                          v-mask="'###.###.###-##'"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.cpf.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select
                          v-model="form.gender"
                          :items="genders"
                          label="Gênero"
                        ></v-select>
                        <p class="red--text" v-if="$v.form.gender.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="mb-5">
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="form.email"
                          label="E-mail:"
                          type="email"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.email.$error">
                          Esse email é inválido*
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <p class="red--text">
                        Preencher o nome completo com atenção, pois será usado
                        no certificado*
                      </p>
                    </v-row>
                  </div>

                  <div v-if="currentStep == 2">
                    <h1 class="titles mt-3">Endereço</h1>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="form.cep"
                          label="CEP:"
                          v-mask="'#####-###'"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.cep.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.street_name"
                          label="Rua:"
                          type="text"
                          :disabled="cepDisablesFields"
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.street_name.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="form.street_number"
                          label="Número:"
                          v-mask="'#######'"
                          required
                        ></v-text-field>
                        <p
                          class="red--text"
                          v-if="$v.form.street_number.$error"
                        >
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.complement"
                          label="Complemento:"
                          type="text"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.neighborhood"
                          :disabled="cepDisablesFields"
                          label="Bairro:"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.neighborhood.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="mb-5">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.city"
                          :disabled="cepDisablesFields"
                          label="Cidade:"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.city.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <!-- <v-text-field
                          v-model="form.state"
                          :disabled="cepDisablesFields"
                          label="Estado:"
                          required
                        ></v-text-field> -->
                        <v-select
                          v-model="form.state"
                          :items="listStates"
                          label="Estado:"
                        ></v-select>
                        <p class="red--text" v-if="$v.form.state.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="currentStep == 3">
                    <h1 class="titles mt-3">Outras informações</h1>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-radio-group v-model="form.carrer" row>
                          <v-col cols="12" md="3">
                            <v-radio label="Estudante" value="1"></v-radio>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-radio label="Técnico" value="2"></v-radio>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-radio
                              label="Profissional de Nível superior"
                              value="3"
                            ></v-radio>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-radio
                              label="Funcionário da Hemorrede"
                              value="4"
                            ></v-radio>
                          </v-col>
                        </v-radio-group>
                        <p class="red--text" v-if="$v.form.carrer.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>

                    <div v-if="form.carrer == 1">
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-select
                            v-model="form.area"
                            :items="studentAreas"
                            label="Estudante de qual área?"
                          ></v-select>
                          <p class="red--text" v-if="$v.form.area.$error">
                            Esse campo é obrigatório*
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col v-if="form.area == 'Outros'" cols="12" md="12">
                          <v-text-field
                            v-model="form.occupation_area"
                            label="Especificar:"
                            required
                          ></v-text-field>

                          <!-- <p class="red--text" v-if="$v.form.isResident.$error">
                          Esse campo é obrigatório*
                        </p> -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col v-if="form.area == 'Medicina'" cols="12" md="12">
                          <v-select
                            v-model="form.isResident"
                            :items="isResident"
                            label="É residente?"
                          ></v-select>
                          <!-- <p class="red--text" v-if="$v.form.isResident.$error">
                          Esse campo é obrigatório*
                        </p> -->
                        </v-col>
                      </v-row>
                      <v-row v-if="form.isResident && form.area == 'Medicina'">
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="form.sector"
                            label="Setor de residência:"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="form.residential_year"
                            label="Ano de residência: "
                            v-mask="'####'"
                            required
                          ></v-text-field>
                          <p
                            class="red--text"
                            v-if="
                              $v.form.residential_year.$error ||
                              form.residential_year != ''
                            "
                          >
                            Mínimo de 4 digitos*
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row v-if="form.carrer == '2'">
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="form.area"
                          label="Técnico em:"
                          required
                        ></v-text-field>

                        <p class="red--text" v-if="$v.form.area.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="form.carrer == 3">
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="form.area"
                          :items="professions"
                          label="Profissional na área de:"
                        ></v-select>

                        <p class="red--text" v-if="$v.form.area.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                      <v-col v-if="form.area == 'Outros'" cols="12" md="12">
                        <v-text-field
                          v-model="form.occupation_area"
                          label="Especificar:"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="form.carrer == 4">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.sector"
                          label="Instituição de trabalho:"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="form.area"
                          label="Área de atuação"
                          required
                        ></v-text-field>
                        <p class="red--text" v-if="$v.form.area.$error">
                          Esse campo é obrigatório*
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="currentStep == 4">
                    <h1 class="titles mt-3">Finalizar Inscrição</h1>

                    <v-row v-if="form.carrer == 1" class="mt-10">
                      <v-col cols="12" md="12">
                        <v-file-input
                          v-model="confirmation_document"
                          label="Enviar carteirinha ou declaração da faculdade"
                          outlined
                          dense
                          right
                          accept=".pdf"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row v-if="form.carrer == '2'" class="mt-10">
                      <v-col cols="12" md="12">
                        <v-file-input
                          v-model="confirmation_document"
                          label="Enviar declaração de curso técnico"
                          outlined
                          dense
                          right
                          accept=".pdf"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row v-if="form.carrer == 3">
                      <v-col cols="12" md="12">
                        <v-file-input
                          v-model="confirmation_document"
                          label="Enviar declaração da instituição ou foto crachá"
                          outlined
                          dense
                          right
                          accept=".pdf"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row v-if="form.carrer == 4">
                      <v-col cols="12" md="12">
                        <v-file-input
                          v-model="confirmation_document"
                          label="Enviar Declaração da empresa ou foto do crachá"
                          outlined
                          dense
                          right
                          accept=".pdf"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </div>
                </b-overlay>
              </v-form>

              <v-row class="step_tollbar ma-0 pa-0">
                <v-col
                  cols="12"
                  class="d-flex justify-center ma-0 my-3 pa-0 px-4"
                >
                  <v-btn
                    v-if="n + 1 < lastStep"
                    color="primary"
                    @click="stepsForm(n)"
                    >Próximo</v-btn
                  >
                  <v-btn v-else color="success" @click="register()"
                    >Enviar</v-btn
                  >
                  <v-btn text v-if="currentStep != 1" @click="currentStep = n"
                    >Voltar</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
// import api from "../axios/axios";
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";
import { api } from "../axios/axios";

export default {
  data: () => ({
    show: false,
    rules: {
      1: {
        full_name: { required, minLength: minLength(3) },
        email: { required, email },
        gender: { required },
        cpf: { required },
        birth_date: { required },
      },
      2: {
        cep: { required },
        street_name: { required },
        street_number: { required },
        neighborhood: { required },
        city: { required },
        state: { required },
      },
      3: {
        // instituion: { required },
        // profession: { required },
        // isResident: { required },
        // student: { required },
        // expertise: { required },
        carrer: { required },
        area: { required },
        residential_year: { minLength: minLength(4) },
        // scholarity: { required },
      },
    },
    currentStep: 1,
    lastStep: 4,
    steps: [
      { name: "Dados pessoais" },
      { name: "Endereço" },
      { name: "Outras informações" },
      { name: "Documentação" },
    ],
    form: {
      id_curso_iph: 66,
      //1
      full_name: "",
      birth_date: "",
      cpf: "",
      email: "",
      gender: "",
      //2
      cep: "",
      street_name: "",
      street_number: "  ",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      //3
      isResident: null,
      carrer: "",
      //4
    },
    confirmation_document: null,
    menu: false,
    activePicker: null,
    valid: "",
    isResident: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    scholarities: [
      "Ensino médio incompleto",
      "Ensino médio completo",
      "Ensino superior incompleto",
      "Ensino superior completo",
    ],

    genders: ["Masculino", "Feminino", "Outro"],
    studentAreas: [
      "Farmácia",
      "Medicina",
      "Odontologia",
      "Biomedicina",
      "Fisioterapia",
      "Educacao",
      "Física",
      "Nutrição",
      "Enfermagem",
      "Biologia",
      "Outros",
    ],

    professions: [
      // "Médico",
      // "Biomédico",
      // "Farmaceutico",
      // "Enfermeiro",
      // "Técnico de enfermagem",
      // "Auxiliar de Enfermagem",
      // "Técnico de Laboratório",
      // "Auxiliar de Laboratório",
      // "Biólogo",

      // "Outro",

      "Assistente Social",
      "Auxiliar de Enfermagem",
      "Auxiliar de Laboratório",
      "Biólogo(a)",
      "Biomédico(a)",
      "Educação Física",
      "Enfermeiro(a)",
      "Farmacêutico(a)",
      "Fisioterapeuta",
      "Médico(a)",
      "Nutricionista",
      "Odontólogo(a)",
      "Psicólogo(a)",
      "Outros",
    ],
    cepDisablesFields: false,
    listStates: [
      { value: "AC", text: "Acre" },
      { value: "AL", text: "Alagoas" },
      { value: "AP", text: "Amapá" },
      { value: "AM", text: "Amazonas" },
      { value: "BA", text: "Bahia" },
      { value: "CE", text: "Ceará" },
      { value: "DF", text: "Distrito Federal" },
      { value: "ES", text: "Espírito Santo" },
      { value: "GO", text: "Goiás" },
      { value: "MA", text: "Maranhão" },
      { value: "MT", text: "Mato Grosso" },
      { value: "MS", text: "Mato Grosso do Sul" },
      { value: "MG", text: "Minas Gerais" },
      { value: "PA", text: "Pará" },
      { value: "PB", text: "Paraíba" },
      { value: "PR", text: "Mato Grosso" },
      { value: "PE", text: "Pernambuco" },
      { value: "PI", text: "Piauí" },
      { value: "RJ", text: "Rio de Janeiro" },
      { value: "RN", text: "Rio Grande do Norte" },
      { value: "RS", text: "Rio Grande do Sul" },
      { value: "RO", text: "Rondônia" },
      { value: "RR", text: "Roraima" },
      { value: "SC", text: "Santa Catarina" },
      { value: "SP", text: "São Paulo" },
      { value: "SE", text: "Sergipe" },
      { value: "TO", text: "Tocantins" },
    ],
  }),

  validations() {
    return {
      form: { ...this.rules[this.currentStep] },
    };
  },

  watch: {
    "form.cep": function () {
      if (this.form.cep.length == 9) {
        this.getCep();
      } else if (this.form.cep.length < 9) {
        this.cepDisablesFields = false;
      }
    },

    "form.carrer": function () {
      const new_form = { ...this.form };
      new_form.area = "";
      new_form.isResident = null;
      new_form.occupation_area = "";
      new_form.residential_year = null;
      new_form.sector = "";
      this.form = new_form;
    },
  },

  methods: {
    // clearFields() {
    //   this.form.sector = "";
    //   this.form.sector = "";
    //   this.form.residential_year = "";
    // },
    // clearTechnicianField() {},
    // clearProfessionalAreaField() {},
    // clearEmployeeField() {
    //   this.form.sector = "";
    //   this.form.area = "";
    // },
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    stepsForm(n) {
      if (this.$v.$invalid) {
        // console.log("com erro");
        if (this.currentStep == 2) {
          this.form.street_name =
            this.form.street_name == "" ? "SEM NOME" : this.form.street_name;
          this.form.street_number =
            this.form.street_number == "" ? 0 : this.form.street_number;
          this.form.neighborhood =
            this.form.neighborhood == "" ? "SEM NOME" : this.form.neighborhood;

          console.log("Aki");
        }
        this.$v.$touch();
      } else {
        if (this.currentStep == 3) {
          // console.log(tssssshis.form);
        }
        if (this.currentStep == 3 && this.form.carrer == 3) {
          this.register();
        } else {
          this.currentStep = n + 2;
        }
      }
    },
    stepComplete(step) {
      return this.currentStep > step;
    },
    stepStatus(step) {
      return this.currentStep > step ? "green" : "blue";
    },
    async register() {
      this.show = true; //MUDAR PRA TRUE
      this.currenteStep = 5;
      this.form.cpf = this.form.cpf.replace(/[^\d]/g, "");

      if (this.form.area == "Outros") {
        this.form.area = this.form.occupation_area;
      }
      console.log(this.form);

      await api
        .post("", {
          email: this.form.email,
          password: this.form.cpf,
          ...this.form,
        })
        .then(() => {
          this.show = false;
        })
        .catch(() => {
          this.show = false;
        });

      if (this.confirmation_document) {
        await this.onUploadFile();
        // this.show = false;
      }

      await this.$swal({
        icon: "success",
        title: "Cadastrado",
        text: "Cadastrado com sucesso, você será redirecionado para a pagina de pagamento. \nO certificado só poderá ser emitido caso você tenha realizado o pagamento.",
      });

      if (this.form.carrer == 1 || this.form.carrer == '2') {
        window.open("https://pag.ae/7YLyybacN");
      } else if (this.form.carrer == 3) {
        window.open("https://pag.ae/7YLyyHShS");
      }

      this.$router.push("/login");

      this.show = false;
    },
    async onUploadFile() {
      const formData = new FormData();

      formData.append("id_curso_iph",this.form.id_curso_iph);

      formData.append(
        "file",
        this.confirmation_document,
        this.confirmation_document.name
      );

      formData.append("cpf", this.form.cpf);

      await api.post("doc", formData);
    },
    getCep() {
      axios
        .get(`https://brasilapi.com.br/api/cep/v1/${this.form.cep}`)
        .then((response) => {
          //coloquei o settime porque sem ele, quando aperta tab, o endereço carrega vazio
          setTimeout(() => {
            this.form.city = response.data.city;
            this.form.state = response.data.state;
            this.form.street_name = response.data.street;
            this.form.neighborhood = response.data.neighborhood;
          }, 1);
          // this.cepDisablesFields = true;
        });
    },
  },
};
</script>
<style scoped>
.paddingOut {
  padding: 20px 40px;
}
.paddingform {
  padding: 50px;
}
.titles {
  text-align: center;
}
.app {
  background: linear-gradient(to right, rgb(211, 29, 29), rgb(168, 114, 14));

  /* min-height: 80vh;  */
}
/* #formSteps{
} */
.step_tollbar {
  background-color: whitesmoke;
}
.v-stepper__header {
  flex-wrap: nowrap;
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .paddingOut {
    padding: 10px 15px;
  }
  .paddingform {
    padding: 10px 5px 0;
  }
}
</style>
