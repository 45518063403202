<template>
  <div v-if="hasSpeakers">
    <v-slide-group show-arrows center-active class="group-speakers">
        <v-slide-item
        v-for="speaker in speakers"
        :key="speaker.id"
        class="ma-5 item-speakers"
        >
        <div class="speakers">
            <b-card img-alt="Image" img-top tag="article">
            <b-img
                fluid
                :src="speaker.img"
                rounded="circle"
                class="img-top shadow shadow-lg--hover speaker-img"
            />
            <div class="pt-4 text-center">
                <h5 class="h3 title">
                <span class="d-block mb-1">{{ speaker.name }}</span>
                <small class="h4 font-weight-light text-muted">{{
                    speaker.instituition
                }}</small>
                </h5>
            </div>
            </b-card>
        </div>
        </v-slide-item>
    </v-slide-group>
  </div>
  <div v-else class="mb-4 pb-4">
    <h1> Em breve! </h1>
  </div>
</template>
  
  <script>
  export default {
    name: "AppSpeakersSlide",
    data() {
      return {
        hasSpeakers: false,
        speakers: [
          {
            id: 1,
            name: "Josineire Pimentel",
            img: require("../../../assets/speakers/josineire.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 2,
            name: "Nathália Martins",
            img: require("../../../assets/speakers/nathaliamartins3.jpeg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 3,
            name: "Lúcio Barbosa",
            img: require("../../../assets/speakers/Lucio2.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 4,
            name: "Fernando Nogueira",
            img: require("../../../assets/speakers/fernandonogueira.jpg"),
            instituition: "Hemoce Sobral",
          },
          {
            id: 5,
            name: "Sterfen Sousa ",
            // Aquino
            img: require("../../../assets/speakers/sterfenAquino.jpg"),
            instituition: "Hemoce Sobral",
          },
          {
            id: 6,
            name: "Ruan Petros",
            img: require("../../../assets/speakers/ruanPetrus.jpeg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 7,
            name: "Ana Kélvia Araújo ",
            // Arcanjo
            img: require("../../../assets/speakers/anaKelvia.jpg"),
            instituition: "Hemoce Sobral",
          },
          {
            id: 8,
            name: "Micheline Aragão ",
            // Fernandes
            img: require("../../../assets/speakers/michelinne2.jpeg"),
            instituition: "Hemoce Sobral",
          },
          {
            id: 10,
            name: "Mercês Rocha",
            img: require("../../../assets/speakers/mercesRocha.jpg"),
            instituition: "Hemoce Crato",
          },
          {
            id: 11,
            name: "Edileine Dellalibera",
            img: require("../../../assets/speakers/edileine.jpeg"),
            instituition: "Hospital Oswaldo Cruz - PE",
          },
          {
            id: 12,
            name: "Ana Paula",
            img: require("../../../assets/speakers/anaPaula.jpg"),
            instituition: "Hospital da Mulher",
          },
          {
            id: 13,
            name: "Denise Brunetta",
            img: require("../../../assets/speakers/denise.png"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 14,
            name: "Viviane Chaves",
            img: require("../../../assets/speakers/vivianeChaves2.jpg"),
            instituition: "Hemoce Crato",
          },
          {
            id: 15,
            name: "Carolina Pinheiro",
            img: require("../../../assets/speakers/carolPinheiro.jpg"),
            instituition: "Hemoce Crato",
          },
          {
            id: 16,
            name: "Andréa Alcântara",
            img: require("../../../assets/speakers/andrea.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 17,
            name: "Deivide Oliveira",
            img: require("../../../assets/speakers/deivide.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 18,
            name: "Fernanda Benevides",
            img: require("../../../assets/speakers/fernandaBenevides.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 19,
            name: "Suzanna Tavares",
            img: require("../../../assets/speakers/suzanna.jpeg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 20,
            name: "Claudianne Lima",
            img: require("../../../assets/speakers/claudiane.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 21,
            name: "Janaina Alves",
            img: require("../../../assets/speakers/janaina2.jpeg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 22,
            name: "Fernanda Brito",
            img: require("../../../assets/speakers/fernandaBrito.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 23,
            name: "Eglidia Vidal",
            img: require("../../../assets/speakers/eglidia.jpg"),
            instituition: "Hemoce Crato",
          },
          {
            id: 24,
            name: "Fernando Barroso",
            img: require("../../../assets/speakers/fernandoBarroso.jpg"),
            instituition: "Hemoce - UFC",
          },
  
          {
            id: 25,
            name: "Anna Karen ",
            // Soares
            img: require("../../../assets/speakers/anaKaren.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 26,
            name: "Tatyane Oliveira",
            img: require("../../../assets/speakers/Tatyane.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 27,
            name: "João Henrique ",
            // Vasconcelos Cavalcante
            img: require("../../../assets/speakers/joaohenrique2.jpeg"),
            instituition: "Hemoce Sobral",
          },
          {
            id: 28,
            name: "Luciana Carlos",
            img: require("../../../assets/speakers/dra-luciana.png"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 29,
            name: "Luany Mesquita",
            img: require("../../../assets/speakers/Dra-luany.png"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 30,
            name: "Franklin Cândido",
            img: require("../../../assets/speakers/Franklin.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 31,
            name: "Tiê Costa",
            img: require("../../../assets/speakers/tie2.jpeg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 32,
            name: "Ana Cristina",
            img: require("../../../assets/speakers/anaCristina.jpeg"),
            instituition: "Hemocentro de Ribeirão Preto",
          },
          {
            id: 33,
            name: "David Marinho",
            img: require("../../../assets/speakers/DavidMarinho.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 34,
            name: "Luiz Ivando",
            img: require("../../../assets/speakers/luizIvando.jpg"),
            instituition: "Hemopi",
          },
          {
            id: 35,
            name: "Nágela Lima",
            img: require("../../../assets/speakers/nagela.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 36,
            name: "Keny Colares",
            img: require("../../../assets/speakers/kenyColares.jpg"),
            instituition: "ESP",
          },
          {
            id: 37,
            name: "Lúcia Silla",
            img: require("../../../assets/speakers/luciaSilla.jpg"),
            instituition: "Rio Grande do Sul",
          },
          {
            id: 38,
            name: "Mabel Fernandes",
            img: require("../../../assets/speakers/mabel.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 39,
            name: "Ricardo Parente",
            img: require("../../../assets/speakers/ricardoParente.jpg"),
            instituition: "Hemoce Crato",
          },
          {
            id: 40,
            name: "Fabiana Aguiar",
            img: require("../../../assets/speakers/fabiana.jpg"),
            instituition: "Hemoce Fortaleza",
          },
          {
            id: 41,
            name: "Rosely Kelly",
            img: require("../../../assets/speakers/roselly.jpeg"),
            instituition: "IJF",
          },
          {
            id: 42,
            name: "Milton Castro",
            img: require("../../../assets/speakers/miltonCastro.jpeg"),
            instituition: "UFC",
          },
          //removido
          // {
          //   id: 43,
          //   name: "Marcos Daniel",
          //   img: require("../assets/speakers/marcosDaniel.jpeg"),
          //   instituition: "UFC",
          // },
        ],
      };
    },
    methods: {},
    mounted() {
      // this.speakers.sort(() => Math.random() - 0.5);
      this.speakers.sort(function(a, b) {
    if(a.name < b.name) {
      return -1;
    } else {
      return true;
    }
  });
    },
  };
  </script>
  
  <style scoped>
  #speakers {
    text-align: center;
    margin-top: 15px;
  }
  .titles {
    text-align: center;
    padding: 30px;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .titles::after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 2px;
    background-color: #ccc;
  }
  
  .container-speaker {
    max-width: 1400px;
  }
  .speakers {
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: space-around; */
  }
  .speakers .card {
    width: 260px;
    border: none;
    margin: 0 auto;
  }
  .speakers .card img {
    object-fit: cover;
    width: 200px;
    height: 200px;
  }
  @media screen and (max-width: 600px) {
   .speakers .card {
    width: 255px;
  }
  }
  
  
  </style>