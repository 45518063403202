import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "./plugins/bootstrap-vue";
import store from "./store/index";
import VueSweetAlert from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

// import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import vuetify from "./plugins/vuetify";
import InputFacade from 'vue-input-facade'

// migrating from v-mask
const options = {
  // rename the directive from: v-facade to: v-mask
  name: 'mask',

  // use these tokens instead of the default
  tokens: {
    '#': { pattern: /\d/ },
    'A': { pattern: /[a-z]/i },
    'N': { pattern: /[0-9a-z]/i },
    'X': { pattern: /./ }
  }
}

Vue.use(InputFacade, options)

Vue.use(VueSweetAlert);
// Vue.use(VueTheMask);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  axios,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
