<template>
    <div id="schedule">
      <b-container>
        <h1 class="titles">Programação</h1>
        <p>(Programação sujeita a alterações)</p>
        <AppScheduleList />
      </b-container>
    </div>
  </template>
  
<script>
import AppScheduleList from "./schedule-list/AppScheduleList.vue";
export default {
    name: "AppSchedule",
    components: {
      AppScheduleList
    },
};
  </script>
  
  <style scoped>
  .buttons {
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .buttons-days {
    width: 340px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .activeDay {
    background-color: #f8c10e;
    color: #000;
    border-radius: 50rem;
  }
  .disableDay {
    background-color: black;
    color: #fff;
  }
  .activeDay,
  .disableDay {
    padding: 10px 15px;
  }
  .content section {
    padding: 15px 0;
  }
  #schedule {
    text-align: center;
    background: linear-gradient(to right, rgb(211, 29, 29), rgb(168, 114, 14));
    padding: 20px;
    color: #fff;
  }
  .titles {
    text-align: center;
    padding: 30px;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .titles::after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 2px;
    background-color: #ccc;
  }
  section {
    text-align: left;
  }
  @media screen and (max-width: 991px) {
    .buttons-days {
      width: 300px;
    }
  }
  </style>
  