import axios from "axios";
import store from "../store";
import Vue from "vue";
import router from "../router";

const api = axios.create({
  baseURL: 'https://prohemo.org.br/iph/api/site',
});

const authInterceptor = (config) => {
  const token = store.state.token;
  if (token) config.headers.Authorization = token;
  return config;
};
const OnResponseSuccess = (response) => response;

const OnResponseFailure = async (error) => {
  const httpStatus = error.response.status;
  const errors = [""];
  switch (httpStatus) {
    case 402:
      Vue.swal({
        icon: "error",
        title: "Erro",
        text: error.response.data.message,
      });
      break;
    case 401:
      if (error.response.data.message) {
        Vue.swal({
          icon: "error",
          title: "Erro",
          text: error.response.data.message,
        });
      } else {
        Vue.swal({
          icon: "error",
          title: "Erro",
          text: "Sessão expirada, faça login novamente.",
        });
      }

      await store.dispatch("logout");
      router.push("/");
      break;
  }
  return Promise.reject(errors);
};

api.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

// const setToken = token => {
//   api.defaults.headers.common["Authorization"] = token;
// };
api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
api.interceptors.request.use(authInterceptor);

export { api };
