export const cronograma = {
    1: {
      manha: [
        {
          hora: "08:00",
          titulo: "Boas Vindas",
          palestrantes:
            "Palestrante: Dra. Luciana Carlos - Diretora Geral do Hemoce, <br /> Dra. Vânia Barreto – Diretora de Ensino e Pesquisa do Hemoce, <br /> Dra. Luany Mesquita – Diretora de Hematologia do Hemoce, <br /> Valdiene Fernandes – Diretora do Hemocentro Regional de Quixadá",
        },
        // {
        //   hora: "-",
        //   titulo:
        //     "MESA REDONDA - Atribuições do Farmacêutico em Hemocentro Segundo a RDC 673/2019",
        //   palestrantes: "",
        //   moderador: " Moderador: Soraia Cunha - Hemoce Sobral",
        // },

        {
          hora: "08:30 - 09:00",
          titulo: "Novas Terapias em Hemofilia.",
          palestrantes: "Palestrante: Luiz Ivando – UFPI. Hemoce",
          moderador: "Moderador: Luany Mesquita – Hemoce",
        },

        {
          hora: "09:00 - 09:30",
          titulo: "Assistência de Enfermagem na Jornada do Paciente com Coagulopatia.",
          palestrantes: "Palestrante: Marcela Ganzela – Ribeirão Preto - SP",
          moderador: "Moderador: Luany Mesquita – Hemoce",
        },
        {
          hora: "09:30 - 10:00",
          titulo: "Avaliação Músculo-esquelético dos pacientes com hemofilia: Ferramentas disponíveis.",
          palestrantes: "Palestrante: RaChiara Barreira – Hemoce",
          moderador: "Moderador: Luany Mesquita – Hemoce",
        },
        {
          hora: "10:00 - 10:30 - INTERVALO",
        },
        {
          hora: "10:30 - 11:00",
          titulo: "Manejo das Complicações Agudas da Doença Falciforme.",
          palestrantes: "Palestrante: Fernanda Benevides – Hemoce",
          moderador: "Moderador: Andréa Alcântara - Hemoce",
        },
        {
          hora: "11:00 - 11:30",
          titulo: "Integração da Rede de Atenção à Pessoa com Doença Falciforme.",
          palestrantes: "Palestrante: Tatyane Oliveira – Hemoce",
          moderador: "Moderador: Andréa Alcântara - Hemoce",
        },
        {
          hora: "11:30 - 12:00",
          titulo: "Integração da Rede de Atenção à Pessoa com Doença Falciforme.",
          palestrantes: "Palestrante: Valeska Queiroz – Hemoce",
          moderador: "Moderador: Andréa Alcântara - Hemoce",
        },
        // {
        //   hora: "11:10",
        //   titulo: "CONFERÊNCIA: Uso do Quelante oral em pacientes com sobrecarga de ferro",
        //   palestrantes: "Palestrante: Ruan Petros - Hemoce Fortaleza",
        //   moderador: "Moderador: Ana Luzia - Hemoce Crato",
        // },
        // {
        //   hora: "11:00",
        //   titulo:
        //     "CONFERÊNCIA: Uso do Quelante oral em pacientes com sobrecarga de ferro",
        //   palestrantes: "Palestrante: Ruan Petros - Hemoce Fortaleza",
        // // },
        // {
        //   hora: "11:40",
        //   titulo: "CONFERÊNCIA: Hemograma nas Leucemias",
        //   palestrantes:
        //     "Palestrante: Ana Kélvia Araújo Arcanjo - Hemoce Sobral",
        // },
        // {
        //   hora: "12:10",
        //   titulo: "Teste direto e indireto da antiglobulina",
        //   palestrantes:
        //     "Palestrante: Micheline Aragão Fernandes - Hemoce Sobral",
        // },
        // {
        //   hora: "12:40",
        //   titulo:
        //     "CONFERÊNCIA: Atuação do Farmacêutico na Equipe Multidisciplinar",
        //   palestrantes: "Palestrante: Mercês Rocha - Hemoce Crato",
        // },
        {
          hora: "12:00 - 14:00",
          titulo: "Encerramento",
          palestrantes: "",
        },
      ],
      tarde: [
        {
          hora: "14:00 - 14:40",
          titulo: "Contribuição da Citometria de Fluxo nas Trombopatias",
          palestrantes: "Palestrante: Mabel Fernandes- Hemoce",
          moderador: "Moderador: Alanna Castro – Hemoce",
        },
        {
          hora: "14:40 - 15:20",
          titulo: "O Impacto das Múltiplas Coletas de Sangue na Saúde do Paciente",
          palestrantes: "Palestrante: Mabel Fernandes- Hemoce",
          moderador: "Moderador: Jannison Ribeiro – Hemoce e IPH",
        },
        {
          hora: "15:20 - 15:40",
          titulo: "Mesa Redonda: Reações Transfusionais Tardias",
          palestrantes: "Palestrante: Mabel Fernandes- Hemoce",
          moderador: "Moderador: Suzanna Tavares – Hemoce ",
        },
        {
          hora: "15:40 - 16:00",
          titulo: "Refratariedade à Transfusão de Plaquetas",
          palestrantes: "Palestrante: Denise Brunetta – Hemoce ",
          moderador: "Moderador: Suzanna Tavares – Hemoce ",
        },
        {
          hora: "16:00 - 16:10",
          titulo: "Debate",
          palestrantes: "",
        },
        {
          hora: "16:10 - 16:20",
          titulo: "Intevalo",
          palestrantes: "",
        },
        {
          hora: "16:20 – 16:50",
          titulo: "A Leucorredução como Medida de Segurança Transfusional",
          palestrantes: "Palestrante: Hélio Moraes – UFTM - Uberaba- MG",
          moderador: "Moderador: Ormando Campos - IPH",
        },
        {
          hora: "16:50 – 17:30",
          titulo: "Implantação da Plataforma NAT PLUS",
          palestrantes: "Palestrante: Richeyla Custódio – Hemoce",
          moderador: "Moderador: Ormando Campos - IPH",
        },
      ],
    },
    2: {
      manha: [
        {
          hora: "08:00 – 08:30",
          titulo: "Estratégias para o Uso Racional de Sangue pelas Agências Transfusionais",
          palestrantes: "Palestrante: Franklin Cândido – Hemoce",
          moderador: "Moderador: Luany Mesquita - Hemoce",
        },
        {
          hora: "08:30 - 08:50",
          titulo: "Mesa Redonda: Hemorragia Grave - Testes Convencionais e Viscoelásticos na Avaliação do Paciente com Hemorragia Grave",
          palestrantes: "Palestrante: Luany Mesquita – Hemoce",
          moderador: "Moderador: Rosângela Ribeiro – Hemoce",
        },
        {
          hora: "08:50 - 09:10",
          titulo: " Manuseio da Hemorragia Pós-parto",
          palestrantes: "Palestrante: Emilcy Rebouças – MEAC / UFC",
          moderador: "Moderador: Rosângela Ribeiro – Hemoce",
        },
        {
          hora: "09:10 - 09:30",
          titulo: "Manuseio da Hemorragia no Período Neonatal",
          palestrantes: "Palestrante: Ana Paula Constâncio– Hospital da Mulher",
          moderador: "Moderador: Rosângela Ribeiro – Hemoce",
        },
        {
          hora: "09:30 - 09:45",
          titulo: "Debate",
          palestrantes: "",
          moderador: "Moderador: Rosângela Ribeiro – Hemoce",
        },
        {
          hora: "09:45 - 10:00",
          titulo: "Intervalo",
          palestrantes: "",
        },
        {
          hora: "10:00 - 11:00",
          titulo: "Comitê Transfusional: Importância e Experiência do Hemoce",
          palestrantes: "Palestrante: Franklin Cândido – Hemoce",
          moderador: "Moderador: Rosângela Ribeiro – Hemoce",
        },
        {
          hora: "11:00 - 12:30",
          titulo: "Intervalo",
          palestrantes: "",
        },
        {
          hora: "12:40 - 14:00",
          titulo: "Apresentação dos Trabalhos Científico",
          palestrantes: "",
        },
      ],
      tarde: [
        {
          hora: "14:10 – 14:30",
          titulo: "Mesa Redonda: Patient Blood Management – PBM. Pilares do PBM",
          palestrantes: "Palestrante: Luciana Carlos – Hemoce",
          moderador: "Moderador: Beatriz Pitombeira – Hemoce",
        },
        {
          hora: "14:30 – 14:50",
          titulo: " PBM na Obstetrícia",
          palestrantes: "Palestrante: Shirley  Bruno– HGF",
          moderador: "Moderador: Beatriz Pitombeira – Hemoce",
        },
        {
          hora: "14:50 – 15:10",
          titulo: "PBM na Pediatria",
          palestrantes: "Palestrante: Denise Brunetta  – Hemoce",
          moderador: "Moderador: Beatriz Pitombeira – Hemoce",
        },
        {
          hora: "15:10 – 15:25",
          titulo: "Debate",
          palestrantes: "",
        },
        {
          hora: "15:25 – 15:40",
          titulo: "Intevalo",
          palestrantes: "",
        },
        {
          hora: "15:40 – 16:10",
          titulo: "A Gestante",
          palestrantes: "Palestrante: Denise Brunetta – Hemoce",
          moderador: "Moderador: Márcia Bruno – Hemoce",
        },
        {
          hora: "16:10 – 16:20",
          titulo: "O Neonato",
          palestrantes: "Palestrante: Ana Paula Constâncio – Hospital da Mulher",
          moderador: "Moderador: Márcia Bruno – Hemoce",
        },
        {
          hora: "16:20 – 16:30",
          titulo: "Debate",
          palestrantes: "Palestrante: Tiê Costa - Hemoce",
          moderador: "Moderador: Márcia Bruno – Hemoce",
        },
        {
          hora: "16:30 – 17:00",
          titulo: "Painel: Pesquisa e Inovação no Hemoce - Bio Engenharia Tecidual",
          palestrantes: "Palestrante: Jannison Ribeiro – Hemoce e IPH",
          moderador: "Moderador: Márcia Bruno – Hemoce",
        },
        {
          hora: "17:00 – 17:30",
          titulo: "Adesivos Regenerativos à Base de Hidrogeis",
          palestrantes: "Palestrante: Vânia Ceccatto – UECE",
          moderador: "Moderador: Márcia Bruno – Hemoce",
        },
        {
          hora: "18:00",
          titulo: "Encerramento",
          palestrantes: "",
        },
      ],
    },
    3: {
      manha: [
        {
          hora: "08:30 – 09:10",
          titulo: "Captação de Doadores de Sangue: Desafios do Hemoce",
          palestrantes: "Palestrante: Nágela Lima – Hemoce",
          moderador: "Moderador: Valdiene Fernandes  - Hemoce",
        },
        {
          hora: "09:10 – 09:50",
          titulo: " Experiência Brasileira em Sangue Raro",
          palestrantes: "Palestrante: Marcelo Addas – Campinas SP",
          moderador: "Moderador: Helena Pitombeira  - Hemoce",
        },
        {
          hora: "09:50 – 10:30",
          titulo: "O Projeto Cearense de CAR-T cell",
          palestrantes: "Palestrante: Fernando Barroso – Hemoce / UFC",
          moderador: "Moderador: Helena Pitombeira  - Hemoce",
        },
        {
          hora: "10:30 – 10:40",
          titulo: "Intevalo",
          palestrantes: "",
        },
        {
          hora: "10:40 – 11:20",
          titulo: " Trombocitopenias: Como Investigar",
          palestrantes: "Palestrante: Gesio Eduardo – Hemoce Crato",
          moderador: "Moderador: Vânia Barreto – Hemoce",
        },
        {
          hora: "11:20 – 12:00",
          titulo: "Hepatites",
          palestrantes: "Palestrante: Milton Castro – UFC",
          moderador: "Moderador: Vânia Barreto – Hemoce",
        },
        {
          hora: "12:00 – 14:00",
          titulo: "Intevalo",
          palestrantes: "",
        },
      ],
      tarde: [
        {
          hora: "14:00 – 14:20",
          titulo: "Mesa Redonda: PBM no Contexto Cirúrgico - Anemia no Peri-operatório",
          palestrantes: "Palestrante: Bruno Benites – Unicamp",
          moderador: "Moderador: Denise Brunetta– Hemoce",
        },
        {
          hora: "14:20 – 14:40",
          titulo: "Experiência do Hemoce em Recuperação intraoperatória de Sangue",
          palestrantes: "Palestrante: Claudianne Lima – Hemoce",
          moderador: "Moderador: Denise Brunetta– Hemoce",
        },
        {
          hora: "14:40 – 14:50",
          titulo: "Debate",
          palestrantes: "",
        },
        {
          hora: "14:50 – 15:10",
          titulo: "Mesa Redonda: Aférese - Coleta Stem Cell",
          palestrantes: "Palestrante: Suzzana Tavares – Hemoce",
          moderador: "Moderador: Karine Sampaio – Hemoce",
        },
        {
          hora: "15:10 – 15:30",
          titulo: " Registro Nacional de Púrpura Trombocitopênica Trombótica - PTT",
          palestrantes: "Palestrante: Fernanda Orsi – Unicamp - SP",
          moderador: "Moderador: Karine Sampaio – Hemoce",
        },
        {
          hora: "15:30 – 15:50",
          titulo: "Eritrocitaférese",
          palestrantes: "Palestrante: Benedito Prado – Ribeirão Preto",
          moderador: "Moderador: Karine Sampaio – Hemoce",
        },
        {
          hora: "15:50",
          titulo: "Debate",
          palestrantes: "",
        },
        {
          hora: "16:00 – 16:30",
          titulo: "Cenário das arboviroses no Brasil e no Ceará: o que esperar para 2023...",
          palestrantes: "Palestrante: Luciano Pamplona – SESA",
          moderador: "Moderador: Régis Gomes – Hemoce Sobral",
        },
        {
          hora: "17:00",
          titulo: "Encerramento",
          palestrantes: "",
        },
      ],
    },
  }