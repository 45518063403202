<template>
    <div id="speakers">
      <v-sheet class="mx-auto container-speaker" elevation="8">
        <h1 class="titles">Palestrantes</h1>
        <AppSpeakersSlide />
      </v-sheet>
    </div>
  </template>
  
  <script>
  import AppSpeakersSlide from "./speakersslide/AppSpeakersSlide.vue";
  export default {
    name: "AppSpeakers",
    components: {
        AppSpeakersSlide
    }
  };
  </script>
  
  <style scoped>
  #speakers {
    text-align: center;
    margin-top: 15px;
  }
  .titles {
    text-align: center;
    padding: 30px;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .titles::after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 2px;
    background-color: #ccc;
  }
  
  .container-speaker {
    max-width: 1400px;
  }  
  </style>