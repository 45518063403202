<template>
  <div id="navbar">
    <b-navbar toggleable="lg" class="nav mb-3">
      <div class="logobar">
        <a href="https://prohemo.org.br">
          <b-img
            class="img-logo-pc"
            :src="require('@/assets/logo-iph-hori-color.png')"
          ></b-img>
        </a>
        <b-img
          class="img-logo"
          :src="require('@/assets/logo-hemoce-separada.png')"
        ></b-img>
        <b-img
          class="img-logo-pc"
          :src="require('@/assets/logo-governo-separada.png')"
        ></b-img>

        <div class="btnlogin">
          <span class="m-3 user" v-if="isLogged">
            Bem vindo - {{ user.nome_aluno_iph }}
          </span>
          <b-button href="/login" class="btn btn-danger" v-if="!isLogged"
            >Entrar</b-button
          >
          <a href="/perfil" class="border m-2" v-if="isLogged">
            <b-icon
              icon="person-circle"
              class="rounded-circle m-1"
              variant="danger"
              font-scale="1.8"
            ></b-icon>
          </a>
          <a class="btn btn-danger" @click="userLogout()" v-if="isLogged"
            >Sair</a
          >
        </div>
      </div>

      <b-navbar-toggle class="m-auto" target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mt-3">
          <b-nav-item to="/" @click="scroll('speakers')">
            Palestrantes
          </b-nav-item>
          <b-nav-item to="/" @click="scroll('schedule')">
            Programação
          </b-nav-item>
          <!-- <b-nav-item to="/enviar-resumo" v-if="isLogged">
            Submissão de trabalhos
          </b-nav-item> -->
          <!-- <b-nav-item to="/acompanhar-resumos" v-if="isLogged">
            Acompanhar tabalhos
          </b-nav-item> -->
          <!-- <b-nav-item>Produtos</b-nav-item>   -->
          <b-nav-item to="/" @click="scroll('formSteps')" v-if="!isLogged"
            >Inscreva-se</b-nav-item
          >
          <b-nav-item href="https://ipheduca.eadplataforma.app/">
            Cursos IPH Educa
          </b-nav-item>
          <!-- <b-nav-item href="https://loja.prohemo.org.br">
            Lojinha virtual
          </b-nav-item> -->
          <b-nav-item to="/links-jornada" v-if="isLogged">
            Links da jornada
          </b-nav-item>
          <b-nav-item
            to="/certificacao"
            v-if="isLogged && $store.state.user.adm !== null"
          >
            Certificação
          </b-nav-item>

          <b-nav-item @click="getCertificate()" v-if="isLogged">
            <span class="text-danger">
              Baixar Certificado
            </span>
          </b-nav-item>
          <!-- <b-nav-item to="/login" v-if="!isLogged">Login</b-nav-item> -->
          <!-- <b-nav-item to="/logout" v-if="isLogged">Logout</b-nav-item> -->
          <!-- PERGUNTAS FREQUENTES -->
          <!-- <b-nav-item to="/faqs">Perguntas frequentes</b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import store from "../store/index";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      certificate_url: "",
    };
  },
  computed: {
    isLogged() {
      return store.state.isLogged;
    },
    user() {
      return store.state.user;
    },
    id_aluno() {
      return store.state.user.id_aluno_iph;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    userLogout() {
      this.logout();
    },

    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      // console.log(this.isLogged);
    },

    getCertificate() {
      this.certificate_url =
        "https://prohemo.org.br/certificate/course/get?id_aluno=" +
        this.id_aluno +
        "&id_curso=60&certificate_type=7";
      axios.get(this.certificate_url).then((response) => {
        if (response.data.error) {
          this.$swal({
            title: "Atualize seus documentos!",
            text:
              "Para receber o certificiado atulize seu documento comprobatorio e aguarde a liberação pela a coordenação do curso.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.href = "/perfil"
            }
          });
        } else {
          window.open(this.certificate_url, "_blank")
        }
      });
    },
  },
};
</script>

<style scoped>
.img-logo {
  max-width: 200px;
}

.img-logo-pc {
  max-width: 200px;
}
#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}
.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
}
.logobar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 80vw;
}
.logobar img {
  /* border: 1px solid red; */
}
/* a:hover{
    color: red  !important;;
} */
.btnlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
@media screen and (max-width: 991px) {
  .nav {
    flex-direction: row-reverse;
  }
  .img-logo-pc {
    display: none;
  }

  #nav-collapse {
    margin-left: 50px;
  }
  .logobar {
    /* flex-direction: column-reverse; */
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    /* border: 1px solid red; */
  }
  .btnlogin {
    width: 100%;
    justify-content: flex-end;
  }
  .user {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .img-logo {
    width: 120px;
  }
}
</style>
