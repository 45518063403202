import Vue from "vue";
import Vuex from "vuex";
import { api } from "../axios/axios";
import router from "../router";

Vue.use(Vuex);
// export interface AppState {
//   user: string;
// }

export default new Vuex.Store({
  state: {
    user: null,
    isLogged: false,
    token: localStorage.getItem("token"),
  },
  mutations: {
    ISLOGGED(state, payload) {
      state.isLogged = payload;
    },
    LOGOUT(state) {
      state.isLogged = false;
    },
    SET_user(state, payload) {
      state.user = payload;
    },
    SET_token(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    isLogged({ commit }, loged) {
      commit("ISLOGGED", loged);
      // console.log(loged);
    },
    async login({ commit, dispatch }, payload) {
      const response = await api.post("/login", payload);
      // commit("SET_user", response.data);
      if (response.data.token) {
        commit("SET_token", `Bearer ${response.data.token}`);
        localStorage.setItem("token", `Bearer ${response.data.token}`);
        dispatch("isLogged", true);
        dispatch("getPerfil");
        router.push({ name: "Home" });
      }
    },
    logout({ commit, dispatch }) {
      localStorage.clear();
      dispatch("isLogged", false);
      commit("SET_user", null);
      commit("SET_token", null);
      commit("LOGOUT");
      router.push({ name: "Home" });
    },
    async getPerfil({ commit, dispatch }) {
      const response = await api.get("/aluno/perfil");
      commit("SET_user", response.data);
      dispatch("isLogged", true);
    },
    async completeAuth({ dispatch, state }) {
      if (state.token && !state.isLogged) await dispatch("getPerfil");
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },

    getAuth(state) {
      return state.isLogged;
    },
  },
  modules: {},
});
