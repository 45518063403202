import { render, staticRenderFns } from "./AppSpeakers.vue?vue&type=template&id=a8ba3026&scoped=true&"
import script from "./AppSpeakers.vue?vue&type=script&lang=js&"
export * from "./AppSpeakers.vue?vue&type=script&lang=js&"
import style0 from "./AppSpeakers.vue?vue&type=style&index=0&id=a8ba3026&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8ba3026",
  null
  
)

export default component.exports