<template>
  <div id="about">
    <div class="about w-100">
      <!-- <div class="text-center banner-link p-5" v-if="!isLogged">
        <h1 class="mb-5">Atenção</h1>

        <p text-variant="white" class="p-banner justify">
          Os certificados estão disponiveis. Acesse o sistema para baixar-lo.
        </p>
        <b-button href="/login" pill variant="warning" class="mb-3"
          >Acessar</b-button
        >
      </div>
      <div class="text-center banner-link p-5" v-else-if="isLogged">
        <h1 class="mb-5">Atenção</h1>

        <p text-variant="white" class="p-banner justify">
          Os certificados estão disponiveis.
        </p>
        <b-button @click="getCertificate()" pill variant="warning" class="mb-3"
          >Baixe Aqui</b-button
        >
      </div> -->
      <img src="../assets/banner-site-iph-01.jpeg" alt="" />

      <!-- COMENTA DAQUI -->
      <!-- <b-button
        pill
        variant="warning"
        to="/"
        @click="scroll('formSteps')"
        class="btn-banner-topo"
        >Inscreva-se
      </b-button> -->

      <!-- ATE AQUI -->
    </div>

    <div class="content-about">
      <b-container class="mb-5">
        <!-- COMENTA daqui -->
        <!-- COMENTA ate aqui -->
        <h1 class="pt-5 pb-5 text-center">Apresentação</h1>
        <p>
          <!-- A Hemorrede Estadual do Ceará realizará a XXVII Jornada Regional de
          Hematologia e Hemoterapia-Crato, XXVIII Jornada Regional de
          Hematologia e Hemoterapia-Sobral, XI Encontro Regional de Enfermagem
          em Hematologia e Hemovigilância e III Encontro Regional de Farmácia em
          Hematologia e Hemoterapia. Trata-se de um evento de 30 horas com
          certificação ministrado por profissionais da Hemorrede Estadual e
          convidados. Será realizado em plataforma virtual de forma síncrona e
          ficará disponível para acesso remoto aos inscritos. Haverá ainda
          disponibilização de material de apoio através da plataforma. -->

          A Hemorrede Estadual do Ceará realizará a XVIII Jornada Cearense de Hematologia e Hemoterapia
XXIX Jornada Regional de Hematologia e Hemoterapia de Quixadá, VII Simpósio Cearense de Coagulopatias e Hemoglobinopatias. Trata-se de um evento de 30 horas com certificação ministrado por profissionais da Hemorrede Estadual e convidados. Será realizado em plataforma virtual de forma síncrona e ficará disponível para acesso remoto aos inscritos.
        </p>

        <h1 class="pt-5 pb-5 text-center">Objetivo</h1>
        <p>
          Qualificar os profissionais da área de saúde e afins, tendo como foco
          a atualização científica e crescimento de médicos, enfermeiros,
          farmacêuticos e equipe multidisciplinar.
        </p>
        <!-- <p class="mb-3">
          No Ceará, são 04 Hemocentros Regionais (Quixadá, Iguatu, Crato e
          Sobral), um Hemonúcleo em Juazeiro do Norte, um posto de coleta no
          Instituto Dr.José Frota - IJF e o Hemocentro Coordenador, com sede em
          Fortaleza.
        </p> -->
        <!-- PERGUNTAS FREQUENTES -->
        <!-- <div class="text-center">
          <b-button pill variant="warning" class="mt-5 mb-5" to="/faqs"
            >Perguntas frequentes</b-button
          >
        </div> -->
      </b-container>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import axios from "axios";

export default {
  data() {
    return {
      certificate_url: "",
    };
  },
  computed: {
    isLogged() {
      return store.state.isLogged;
    },
    id_aluno() {
      return store.state.user.id_aluno_iph;
    },
  },
  created() {
    this.certificate_url =
      "https://prohemo.org.br/certificate/course/get?id_aluno=" +
      this.id_aluno +
      "&id_curso=60&certificate_type=7";
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      // console.log(this.isLogged);
    },

    getCertificate() {
      this.certificate_url =
        "https://prohemo.org.br/certificate/course/get?id_aluno=" +
        this.id_aluno +
        "&id_curso=60&certificate_type=7";
      axios.get(this.certificate_url).then((response) => {
        if (response.data.error) {
          this.$swal({
            title: "Atualize seus documentos!",
            text:
              "Para receber o certificiado atulize seu documento comprobatorio e aguarde a liberação pela a coordenação do curso.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.href = "/perfil";
            }
          });
        } else {
          window.open(this.certificate_url, "_blank");
        }
      });
    },
  },
};
</script>

<style scoped>
.banner-link {
  background: #bc0723;
  width: 100vw;
  border: 1px solid white;
}
#about {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #bc0723; */

  color: #fff;
}
.p-banner {
  font-size: 1.5rem;
}
.bg-btn {
  background: #fff;
  border: 1px solid white;
  padding: 20px;
}
.about {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  color: #fff;
}
#about img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.btn-banner-topo {
  position: absolute;
  bottom: 70px;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.content-about {
  width: 100vw;
  margin: 0 auto;
  text-align: justify;
  /* margin-top: 20px; */
  background: #bc0723;
}

@media screen and (max-width: 991px) {
  .btn-banner-topo {
    position: absolute;
    bottom: 30px;
  }
  .p-banner {
    font-size: 1rem;
  }
}
</style>
