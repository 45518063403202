<template>
  <div id="home">
    <About></About>
    <!-- <Banner></Banner> -->
    <AppSpeakers />
    <!-- <Countdown></Countdown> -->
    <AppSchedule />
    <Organizers></Organizers>

    <FormSteps></FormSteps>

    <Supportes></Supportes>

    <Footer></Footer>
  </div>
</template>

<script>
// import Banner from "../components/Banner.vue";
import About from "../components/About.vue";
import AppSpeakers from "@/components/speakers/AppSpeakers.vue"
import Footer from "../components/Footer.vue";
import Supportes from "../components/Supportes.vue";
import FormSteps from "../components/FormSteps.vue";
// import Countdown from "../components/Countdown.vue";
import AppSchedule from "@/components/schedule/AppSchedule.vue";
import Organizers from "../components/Organizers.vue";
import { mapActions } from "vuex";
export default {
  name: "Home",
  components: {
    // Banner,
    About,
    AppSpeakers,
    Footer,
    Supportes,
    FormSteps,
    // Countdown,
    AppSchedule,
    Organizers,
  },
  methods: {
    ...mapActions(["isLogged"]),
  },
  mounted() {},
};
</script>
