<template>
  <div id="footer-main">
    <b-container class="footer">
      <span>IPH Sistemas - &copy;</span>
      <a href="/files/politicas.pdf" download>Política de Privacidade</a>
      <!-- <b-row>
        <b-col>
          <img src="@/assets/logo-iph-white.png" alt="" class="img-footer" />
        </b-col>
        <b-col class="my-auto">
      
          <a href="#">
            <b-icon icon="instagram" font-scale="1.5" variant="info"></b-icon>
          </a>
        </b-col>
        <b-col class="my-auto">
          <a href="#">
            <b-icon icon="facebook" font-scale="1.5" variant="info"></b-icon>
          </a>
        </b-col>
        <b-col class="m-auto">
          <b-button variant=""> Ajuda </b-button>
        </b-col> -->
      <!-- </b-row> -->
    </b-container>
  </div>
</template>

<script>
export default {
  name: "footer-main",
};
</script>

<style scoped>
#footer-main {
  background: #222;
  color: #fff;
}
.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
