import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
// import api from "../axios/axios";
// import axios from 'axios'

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter(to, from, next) {
    //   console.log("OI eu sou o goku");

    //   api.post("664/posts", {text:'blabla'}).then((resp) => {
    //     axios.defaults.headers.post["authorization"] = localStorage.token;
    //     console.log(resp)
    //   });
    //   next();
    // },
    meta: {
      name: "Início",
    },
  },
  // {
  //   path: "/enviar-resumo",
  //   name: "Resume",
  //   component: () =>
  //     import(/* webpackChunkName: "Resume" */ "../views/Resume.vue"),
  //   // meta:{requiresAuth:true},
  //   // beforeEnter(to, from, next){
  //   //   console.log(to.matched, 'aki')
  //   //   console.log(from)
  //   //   console.log(next)
  //   // }
  //   meta: {
  //     anonimousOnly: false,
  //     requiresAuth: true,
  //     name: "Enviar Resumos"
  //   }
  // },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: {
      requiresAuth: false,
      anonimousOnly: true,
      name: "Entrar",
    },
  },
  {
    path: "/links-jornada",
    name: "Links",
    component: () =>
      import(/* webpackChunkName: "Resume" */ "../components/Links.vue"),
    // meta:{requiresAuth:true},
    // beforeEnter(to, from, next){
    //   console.log(to.matched, 'aki')
    //   console.log(from)
    //   console.log(next)
    // }
    meta: {
      anonimousOnly: false,
      requiresAuth: true,
      name: "Links Jornada",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "Logout" */ "../views/Logout.vue"),
  },
  // PERGUNTAS FREQUENTES
  // {
  //   path: "/faqs",
  //   name: "Faqs",
  //   component: () => import(/* webpackChunkName: "Faqs" */ "../views/Faqs.vue")
  // },
  {
    path: "/acompanhar-resumos",
    name: "ResumeStatus",
    component: () =>
      import(/* webpackChunkName: "Faqs" */ "../views/ResumeStatus.vue"),
    meta: {
      requiresAuth: true,
      anonimousOnly: false,
      name: "Acompanhar Resumos",
    },
  },
  {
    path: "/certificacao",
    name: "Certified",
    component: () =>
      import(/* webpackChunkName: "Faqs" */ "../views/Certified.vue"),
    meta: {
      requiresAuth: true,
      anonimousOnly: false,
      name: "Acompanhar certificação",
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: () =>
      import(/* webpackChunkName: "Faqs" */ "../views/Perfil.vue"),
    meta: {
      requiresAuth: true,
      anonimousOnly: false,
      name: "Visualizar Perfil",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

const requiresAuthGuard = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = !!store.state.isLogged;
    if (!isLoggedIn) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
    return true;
  }
  return false;
};

const anonimousGuard = (to, from, next) => {
  if (to.matched.some((record) => record.meta.anonimousOnly)) {
    const isAnonimous = !store.state.user;
    if (!isAnonimous) {
      next({ name: "Home" });
    } else {
      next();
    }
    return true;
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  // const acceptedRoutes = ["/logout", "/enviar-resumo"];

  // if (acceptedRoutes.includes(path) && !store.getters["getAuth"])
  //   return router.push("/");
  // return next();
  await store.dispatch("completeAuth");
  document.title = `${to.meta.name} :: Jornada`;
  if (requiresAuthGuard(to, from, next)) {
    return;
  }
  if (anonimousGuard(to, from, next)) {
    return;
  }
  next();
});

export default router;
