<template>
    <div>
        <template v-if="hasSchedule">
            <div class="buttons-days mb-2">
            <span
                @click="dia = 1"
                class="activeDay"
                :class="dia == 1 ? 'activeDay' : 'disableDay'"
                >29/11/22</span
            >
            <span
              @click="dia = 2"
              class="activeDay"
              :class="dia == 2 ? 'activeDay' : 'disableDay'"
            >
              30/11/22
            </span>
            <span
              @click="dia = 3"
              class="activeDay"
              :class="dia == 3 ? 'activeDay' : 'disableDay'"
            >
              01/12/22
            </span>
            </div>
            <div class="buttons">
            <span
                @click="turno = 'manha'"
                class="activeDay"
                :class="turno == 'manha' ? 'activeDay' : 'disableDay'"
                >Manhã</span
            >
            <span
              @click="turno = 'tarde'"
              class="activeDay"
              :class="turno == 'manha' ? 'disableDay' : 'activeDay'"
            >
              Tarde
            </span>
            </div>
            <div class="content">
            <div v-for="(item, index) in cronograma[dia][turno]" :key="index">
                <section>
                    <h3 v-if="item.hora">{{ item.hora }}</h3>
                    <h4 v-if="item.titulo">{{ item.titulo }}</h4>
                    <p v-if="item.moderador">{{ item.moderador }}</p>
                    <p>
                    <small v-html="item.palestrantes"></small>
                    </p>
                    <!-- <b-button variant="warning">Acessar conteudo</b-button> -->
                </section>
                <hr />
                </div>
            </div>
        </template>
    </div>
</template>
  
<script>
import { cronograma } from "./cronograma";
export default {
    name: "AppSchedule",
    data() {
      return {
        // show: true,
        // turno: true,
        hasSchedule: true,
        cronograma: cronograma,
        dia: 1,
        turno: "manha",
        selected: [],
      };
    },
    methods: {
      isActiveTrue() {
        this.show = true;
      },
      isActiveFalse() {
        this.show = false;
      },
      selectDay() {},
      selectTurno() {},
    },
    watch: {
      show() {
        return this.show;
      },
    },
  };
  </script>
  
  <style scoped>
  .buttons {
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .buttons-days {
    width: 340px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .activeDay {
    background-color: #f8c10e;
    color: #000;
    border-radius: 50rem;
  }
  .disableDay {
    background-color: black;
    color: #fff;
  }
  .activeDay,
  .disableDay {
    padding: 10px 15px;
  }
  .content section {
    padding: 15px 0;
  }
  #schedule {
    text-align: center;
    background: linear-gradient(to right, rgb(211, 29, 29), rgb(168, 114, 14));
    padding: 20px;
    color: #fff;
  }
  .titles {
    text-align: center;
    padding: 30px;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .titles::after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 2px;
    background-color: #ccc;
  }
  section {
    text-align: left;
  }
  @media screen and (max-width: 991px) {
    .buttons-days {
      width: 300px;
    }
  }
  </style>
  