<template>
  <div id="organizers">
    <b-container>
      <div class="d-flex">
        <h1 class="titles m-auto mb-5 mt-3">Organizadores</h1>
      </div>
      <div class="organizers">
        <b-card
          v-for="organizer in organizers"
          :key="organizer.id"
          :img-src="organizer.img"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 p-2"
        >
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Organizers",
  data() {
    return {
      organizers: [
        {
          id: 2,
          title: "Hemoce",
          text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
          img:require("@/assets/logo-hemoce-separada.png") ,
          
        },
        {
          id: 3,
          title: "Governo do Ceara",
          text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
           img:require("@/assets/logo-governo-separada.png") 
            
        },
      ],
    };
  },
};
</script>

<style scoped>
#organizers {
  text-align: left;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.organizers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.organizers .card {
  width: 280px;
  height: 200px;
  border: none;
}

.card-body {
  text-align: center;
}
.card img {
  height: 250px;
  object-fit: contain;
}
/* .card-body a icon{
    text-align: left;
} */
@media screen and (max-width: 991px) {
   .organizers .card {
  width: 210px;
  height: 200px;
  border: none;
}

}
@media screen and (max-width: 450px) {
  .organizers .card {
  width: 180px;
  height: 200px;
  border: none;
}
.card img {
  height: 150px;
  object-fit: contain;
}
}
</style>